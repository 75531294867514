import { GeneratorType } from "./generatorType";
import { RegionType } from "./regionType";

export const PJM_SOLAR_SCOPE_VIEW = "pjm_solar";
export const PJM_LOAD_SCOPE_VIEW = "pjm_load";
export const PJM_WIND_SCOPE_VIEW = "pjm_wind";

export const MISO_SOLAR_SCOPE_VIEW_TYPE = "miso_solar";
export const MISO_WIND_SCOPE_VIEW_TYPE = "miso_wind";
export const MISO_STORAGE_SCOPE_VIEW_TYPE = "miso_storage";
export const MISO_LOAD_SCOPE_VIEW_TYPE = "miso_load";

export const SPP_SOLAR_SCOPE_VIEW_TYPE = "spp_solar";
export const SPP_WIND_SCOPE_VIEW_TYPE = "spp_wind";

export const WECC_DISCHARGING_SCOPE_VIEW_TYPE = "wecc_discharging";
export const WECC_STORAGE_SCOPE_VIEW_TYPE = "wecc_storage";

export const TVA_SOLAR_SCOPE_VIEW_TYPE = "tva_solar";

export const SOCO_SOLAR_SCOPE_VIEW_TYPE = "soco_solar";

export const ISONE_DISCHARGING_AND_CHARGING_SCOPE_VIEW =
    "isone_discharging_and_charging";
export const ISONE_DISCHARGING_SCOPE_VIEW = "isone_discharging";

export const ERCOT_SP_DISCHARGING_HRML_CHARGING_SCOPE_VIEW =
    "sp_discharging_hrml_charging";
export const ERCOT_SP_CHARGING_SCOPE_VIEW = "sp_charging";
export const ERCOT_HRML_DISCHARGING_SCOPE_VIEW = "hrml_discharging";
export const ERCOT_SP_LOAD_SCOPE_VIEW = "sp_ercot_load";

export const NYISO_SP_DISCHARGING_LL_CHARGING_SCOPE_VIEW =
    "nyiso_sp_discharging_ll_charging";

export const DUKE_SOLAR_SCOPE_VIEW_TYPE = "duke_solar";
export const DUKE_STORAGE_SCOPE_VIEW_TYPE = "duke_storage";

export const SCOPE_VIEW_TYPES = [
    PJM_SOLAR_SCOPE_VIEW,
    PJM_LOAD_SCOPE_VIEW,
    PJM_WIND_SCOPE_VIEW,
    MISO_SOLAR_SCOPE_VIEW_TYPE,
    MISO_WIND_SCOPE_VIEW_TYPE,
    MISO_STORAGE_SCOPE_VIEW_TYPE,
    MISO_LOAD_SCOPE_VIEW_TYPE,
    SPP_SOLAR_SCOPE_VIEW_TYPE,
    SPP_WIND_SCOPE_VIEW_TYPE,
    WECC_DISCHARGING_SCOPE_VIEW_TYPE,
    WECC_STORAGE_SCOPE_VIEW_TYPE,
    TVA_SOLAR_SCOPE_VIEW_TYPE,
    SOCO_SOLAR_SCOPE_VIEW_TYPE,
    ISONE_DISCHARGING_AND_CHARGING_SCOPE_VIEW,
    ISONE_DISCHARGING_SCOPE_VIEW,
    ERCOT_SP_DISCHARGING_HRML_CHARGING_SCOPE_VIEW,
    ERCOT_SP_CHARGING_SCOPE_VIEW,
    ERCOT_HRML_DISCHARGING_SCOPE_VIEW,
    ERCOT_SP_LOAD_SCOPE_VIEW,
    NYISO_SP_DISCHARGING_LL_CHARGING_SCOPE_VIEW,
    DUKE_SOLAR_SCOPE_VIEW_TYPE,
    DUKE_STORAGE_SCOPE_VIEW_TYPE
] as const;
export type ScopeViewType = (typeof SCOPE_VIEW_TYPES)[number];

const REGION_TO_FUEL_TYPE_TO_SCOPE_VIEW: {
    [key in RegionType]: { [key in GeneratorType]: ScopeViewType | undefined };
} = {
    NYISO: {
        solar: NYISO_SP_DISCHARGING_LL_CHARGING_SCOPE_VIEW,
        storage: NYISO_SP_DISCHARGING_LL_CHARGING_SCOPE_VIEW,
        wind: undefined,
        load: undefined
    },
    PJM: {
        solar: PJM_SOLAR_SCOPE_VIEW,
        storage: undefined,
        wind: PJM_WIND_SCOPE_VIEW,
        load: PJM_LOAD_SCOPE_VIEW
    },
    MISO: {
        solar: MISO_SOLAR_SCOPE_VIEW_TYPE,
        storage: MISO_STORAGE_SCOPE_VIEW_TYPE,
        wind: MISO_WIND_SCOPE_VIEW_TYPE,
        load: MISO_LOAD_SCOPE_VIEW_TYPE
    },
    CAISO: {
        solar: undefined,
        storage: undefined,
        wind: undefined,
        load: undefined
    },
    SPP: {
        solar: SPP_SOLAR_SCOPE_VIEW_TYPE,
        storage: undefined,
        wind: SPP_WIND_SCOPE_VIEW_TYPE,
        load: undefined
    },
    ERCOT: {
        solar: ERCOT_SP_DISCHARGING_HRML_CHARGING_SCOPE_VIEW,
        storage: ERCOT_SP_DISCHARGING_HRML_CHARGING_SCOPE_VIEW,
        wind: ERCOT_HRML_DISCHARGING_SCOPE_VIEW,
        load: ERCOT_SP_LOAD_SCOPE_VIEW
    },
    WECC: {
        solar: WECC_DISCHARGING_SCOPE_VIEW_TYPE,
        storage: WECC_STORAGE_SCOPE_VIEW_TYPE,
        wind: undefined,
        load: undefined
    },
    TVA: {
        solar: TVA_SOLAR_SCOPE_VIEW_TYPE,
        storage: undefined,
        wind: undefined,
        load: undefined
    },
    ISONE: {
        solar: ISONE_DISCHARGING_SCOPE_VIEW,
        storage: ISONE_DISCHARGING_AND_CHARGING_SCOPE_VIEW,
        wind: undefined,
        load: undefined
    },
    SOCO: {
        solar: SOCO_SOLAR_SCOPE_VIEW_TYPE,
        storage: undefined,
        wind: undefined,
        load: undefined
    },
    DUKE: {
        solar: DUKE_SOLAR_SCOPE_VIEW_TYPE,
        storage: DUKE_STORAGE_SCOPE_VIEW_TYPE,
        wind: undefined,
        load: undefined
    }
};

export const getScopeViewFromRegionAndGenerator = (
    region: RegionType,
    generatorType: GeneratorType
): ScopeViewType => {
    const maybeScopeView =
        REGION_TO_FUEL_TYPE_TO_SCOPE_VIEW[region][generatorType];

    if (!maybeScopeView) {
        throw new Error("Generator and region combination not supported");
    }

    return maybeScopeView;
};

export const isKnownScopeViewType = (type: string): type is ScopeViewType => {
    return SCOPE_VIEW_TYPES.includes(type as ScopeViewType);
};

export const convertToScopeViewType = (string: string): ScopeViewType => {
    if (isKnownScopeViewType(string)) {
        return string;
    }

    throw new Error("Unexpected scopeView type");
};

export const ERCOT_SCOPE_VIEW_TYPE = [
    ERCOT_SP_DISCHARGING_HRML_CHARGING_SCOPE_VIEW,
    ERCOT_SP_CHARGING_SCOPE_VIEW,
    ERCOT_HRML_DISCHARGING_SCOPE_VIEW
] as const;
export type ErcotScopeViewType = (typeof ERCOT_SCOPE_VIEW_TYPE)[number];

export const isKnownErcotScopeViewType = (
    type: ScopeViewType
): type is ErcotScopeViewType => {
    return ERCOT_SCOPE_VIEW_TYPE.includes(type as ErcotScopeViewType);
};
