import { KV_100, KV_115, KV_138, KV_161, Voltage } from "types/voltageTypes";
import {
    GeneratorType,
    LOAD_TYPE,
    SOLAR_TYPE,
    STORAGE_TYPE,
    WIND_TYPE
} from "./generatorType";
import { getBusFiltersFromQueryParams } from "./queryParams";
import { RegionType } from "./regionType";
import {
    DUKE_DISIS_2023_PHASE_2_SCOPE_TYPE,
    ERCOT_2027_SCOPE_TYPE,
    ERCOT_LOAD_2028_SCOPE_TYPE,
    ISONE_2026_SUMMER_PEAK_SCOPE_TYPE,
    MISO_2022_MTEP_SCOPE_TYPE,
    MISO_2022_REALISTIC_SCOPE_TYPE,
    NYISO_2029_SCOPE_TYPE,
    PJM_2028_LOAD_SCOPE,
    PJM_ALL_QUEUE_SCOPE,
    ScopeType,
    SOCO_2028_SCOPE_TYPE,
    SPP_ALL_GENERATORS_SCOPE_TYPE,
    TVA_2028_WITH_PRIORS_SCOPE_TYPE,
    WECC_LATE_STAGE_GENERATORS_SCOPE_TYPE
} from "./scopeType";

const DEFAULT_CAPACITY_THRESHOLD = {
    lowThreshold: 0,
    mediumThreshold: 20,
    highThreshold: 100
};
const ERCOT_CAPACITY_THRESHOLD = {
    lowThreshold: 0,
    mediumThreshold: 100,
    highThreshold: 250
};

const PJM_FILTER: BusFilters = {
    voltages: [KV_138],
    maybeMaxAllocatedCosts: {
        enabled: true,
        maxCosts: 10_000_000
    },
    maybeMaxTotalCosts: {
        enabled: false,
        maxCosts: 50_000_000
    },
    scope: PJM_ALL_QUEUE_SCOPE,
    hideLockedBuses: false,
    includePreExistingConstraints: true,
    hideLowConfidenceBuses: false,
    capacityThresholds: DEFAULT_CAPACITY_THRESHOLD
};
const PJM_LOAD_FILTER: BusFilters = {
    voltages: [KV_138],
    // Not used for load
    maybeMaxAllocatedCosts: {
        enabled: false,
        maxCosts: 10_000_000
    },
    maybeMaxTotalCosts: {
        enabled: true,
        maxCosts: 0
    },
    scope: PJM_2028_LOAD_SCOPE,
    hideLockedBuses: false,
    includePreExistingConstraints: false,
    hideLowConfidenceBuses: false,
    capacityThresholds: DEFAULT_CAPACITY_THRESHOLD
};
const PJM_WIND_FILTER: BusFilters = {
    voltages: [KV_138],
    maybeMaxAllocatedCosts: {
        enabled: true,
        maxCosts: 10_000_000
    },
    maybeMaxTotalCosts: {
        enabled: false,
        maxCosts: 50_000_000
    },
    scope: PJM_ALL_QUEUE_SCOPE,
    hideLockedBuses: false,
    includePreExistingConstraints: true,
    hideLowConfidenceBuses: false,
    capacityThresholds: DEFAULT_CAPACITY_THRESHOLD
};

const MISO_FILTER: BusFilters = {
    voltages: [KV_138],
    maybeMaxAllocatedCosts: {
        enabled: true,
        maxCosts: 20_000_000
    },
    maybeMaxTotalCosts: {
        enabled: false,
        maxCosts: 50_000_000
    },
    scope: MISO_2022_REALISTIC_SCOPE_TYPE,
    hideLockedBuses: false,
    includePreExistingConstraints: true,
    hideLowConfidenceBuses: false,
    capacityThresholds: DEFAULT_CAPACITY_THRESHOLD
};

const MISO_LOAD_FILTER: BusFilters = {
    voltages: [KV_138],
    // Not used for load
    maybeMaxAllocatedCosts: {
        enabled: false,
        maxCosts: 10_000_000
    },
    maybeMaxTotalCosts: {
        enabled: true,
        maxCosts: 0
    },
    scope: MISO_2022_MTEP_SCOPE_TYPE,
    hideLockedBuses: false,
    includePreExistingConstraints: true,
    hideLowConfidenceBuses: false,
    capacityThresholds: DEFAULT_CAPACITY_THRESHOLD
};

const SPP_FILTER: BusFilters = {
    voltages: [KV_115],
    maybeMaxAllocatedCosts: {
        enabled: true,
        maxCosts: 10_000_000
    },
    maybeMaxTotalCosts: {
        enabled: false,
        maxCosts: 50_000_000
    },
    scope: SPP_ALL_GENERATORS_SCOPE_TYPE,
    hideLockedBuses: false,
    includePreExistingConstraints: true,
    hideLowConfidenceBuses: false,
    capacityThresholds: DEFAULT_CAPACITY_THRESHOLD
};

const ERCOT_FILTERS: BusFilters = {
    voltages: [KV_138],
    // Filter not used in ERCOT
    maybeMaxAllocatedCosts: {
        enabled: false,
        maxCosts: 1_000_000_000
    },
    // Filter not used in ERCOT
    maybeMaxTotalCosts: {
        enabled: false,
        maxCosts: 1_000_000_000
    },
    scope: ERCOT_2027_SCOPE_TYPE,
    hideLockedBuses: false,
    includePreExistingConstraints: true,
    hideLowConfidenceBuses: false,
    capacityThresholds: ERCOT_CAPACITY_THRESHOLD
};

const ERCOT_LOAD_FILTERS: BusFilters = {
    voltages: [KV_138],
    // Filter not used in ERCOT Load
    maybeMaxAllocatedCosts: {
        enabled: false,
        maxCosts: 1_000_000_000
    },
    maybeMaxTotalCosts: {
        enabled: true,
        maxCosts: 0
    },
    scope: ERCOT_LOAD_2028_SCOPE_TYPE,
    hideLockedBuses: false,
    includePreExistingConstraints: false,
    hideLowConfidenceBuses: false,
    capacityThresholds: DEFAULT_CAPACITY_THRESHOLD
};

const WECC_FILTERS: BusFilters = {
    voltages: [KV_115],
    maybeMaxAllocatedCosts: {
        enabled: true,
        maxCosts: 10_000_000
    },
    maybeMaxTotalCosts: {
        enabled: false,
        maxCosts: 50_000_000
    },
    scope: WECC_LATE_STAGE_GENERATORS_SCOPE_TYPE,
    hideLockedBuses: false,
    includePreExistingConstraints: true,
    hideLowConfidenceBuses: false,
    capacityThresholds: DEFAULT_CAPACITY_THRESHOLD
};

const NYISO_FILTERS: BusFilters = {
    voltages: [KV_115],
    // Filter not used in NYISO
    maybeMaxAllocatedCosts: {
        enabled: false,
        maxCosts: 1_000_000_000
    },
    // Filter not used in NYISO
    maybeMaxTotalCosts: {
        enabled: false,
        maxCosts: 1_000_000_000
    },
    scope: NYISO_2029_SCOPE_TYPE,
    hideLockedBuses: false,
    includePreExistingConstraints: true,
    hideLowConfidenceBuses: false,
    capacityThresholds: DEFAULT_CAPACITY_THRESHOLD
};

const TVA_FILTER: BusFilters = {
    voltages: [KV_161],
    // Filter not used in TVA
    maybeMaxAllocatedCosts: {
        enabled: false,
        maxCosts: 10_000_000
    },
    maybeMaxTotalCosts: {
        enabled: true,
        maxCosts: 15_000_000
    },
    scope: TVA_2028_WITH_PRIORS_SCOPE_TYPE,
    hideLockedBuses: false,
    includePreExistingConstraints: true,
    hideLowConfidenceBuses: false,
    capacityThresholds: DEFAULT_CAPACITY_THRESHOLD
};

const ISONE_FILTERS: BusFilters = {
    voltages: [KV_115],
    // Filter not used in ISONE
    maybeMaxAllocatedCosts: {
        enabled: false,
        maxCosts: 1_000_000_000
    },
    maybeMaxTotalCosts: {
        enabled: true,
        maxCosts: 10_000_000
    },
    scope: ISONE_2026_SUMMER_PEAK_SCOPE_TYPE,
    hideLockedBuses: false,
    includePreExistingConstraints: true,
    hideLowConfidenceBuses: false,
    capacityThresholds: DEFAULT_CAPACITY_THRESHOLD
};

const SOCO_FILTERS: BusFilters = {
    voltages: [KV_115],
    maybeMaxAllocatedCosts: {
        enabled: true,
        maxCosts: 10_000_000
    },
    maybeMaxTotalCosts: {
        enabled: false,
        maxCosts: 50_000_000
    },
    scope: SOCO_2028_SCOPE_TYPE,
    hideLockedBuses: false,
    includePreExistingConstraints: true,
    hideLowConfidenceBuses: false,
    capacityThresholds: DEFAULT_CAPACITY_THRESHOLD
};

const DUKE_FILTERS: BusFilters = {
    voltages: [KV_100, KV_115],
    maybeMaxAllocatedCosts: {
        enabled: true,
        maxCosts: 15_000_000
    },
    maybeMaxTotalCosts: {
        enabled: false,
        maxCosts: 50_000_000
    },
    scope: DUKE_DISIS_2023_PHASE_2_SCOPE_TYPE,
    hideLockedBuses: false,
    includePreExistingConstraints: true,
    hideLowConfidenceBuses: false,
    capacityThresholds: DEFAULT_CAPACITY_THRESHOLD
};

const REGIONS_TO_DEFAULT_FILTER: {
    readonly [key in RegionType]: {
        readonly [key in GeneratorType]: BusFilters | undefined;
    };
} = {
    NYISO: {
        [SOLAR_TYPE]: NYISO_FILTERS,
        [WIND_TYPE]: undefined,
        [STORAGE_TYPE]: NYISO_FILTERS,
        [LOAD_TYPE]: undefined
    },
    PJM: {
        [SOLAR_TYPE]: PJM_FILTER,
        [WIND_TYPE]: PJM_WIND_FILTER,
        [STORAGE_TYPE]: undefined,
        [LOAD_TYPE]: PJM_LOAD_FILTER
    },
    MISO: {
        [SOLAR_TYPE]: MISO_FILTER,
        [WIND_TYPE]: MISO_FILTER,
        [STORAGE_TYPE]: MISO_FILTER,
        [LOAD_TYPE]: MISO_LOAD_FILTER
    },
    CAISO: {
        [SOLAR_TYPE]: undefined,
        [WIND_TYPE]: undefined,
        [STORAGE_TYPE]: undefined,
        [LOAD_TYPE]: undefined
    },
    SPP: {
        [SOLAR_TYPE]: SPP_FILTER,
        [WIND_TYPE]: SPP_FILTER,
        [STORAGE_TYPE]: undefined,
        [LOAD_TYPE]: undefined
    },
    ERCOT: {
        [SOLAR_TYPE]: ERCOT_FILTERS,
        [WIND_TYPE]: ERCOT_FILTERS,
        [STORAGE_TYPE]: ERCOT_FILTERS,
        [LOAD_TYPE]: ERCOT_LOAD_FILTERS
    },
    WECC: {
        [SOLAR_TYPE]: WECC_FILTERS,
        [WIND_TYPE]: undefined,
        [STORAGE_TYPE]: WECC_FILTERS,
        [LOAD_TYPE]: undefined
    },
    TVA: {
        [SOLAR_TYPE]: TVA_FILTER,
        [WIND_TYPE]: undefined,
        [STORAGE_TYPE]: undefined,
        [LOAD_TYPE]: undefined
    },
    ISONE: {
        [SOLAR_TYPE]: ISONE_FILTERS,
        [WIND_TYPE]: undefined,
        [STORAGE_TYPE]: ISONE_FILTERS,
        [LOAD_TYPE]: undefined
    },
    SOCO: {
        [SOLAR_TYPE]: SOCO_FILTERS,
        [WIND_TYPE]: undefined,
        [STORAGE_TYPE]: undefined,
        [LOAD_TYPE]: undefined
    },
    DUKE: {
        [SOLAR_TYPE]: DUKE_FILTERS,
        [WIND_TYPE]: undefined,
        [STORAGE_TYPE]: DUKE_FILTERS,
        [LOAD_TYPE]: undefined
    }
};

export type MaybeMaxCosts = {
    readonly enabled: boolean;
    readonly maxCosts: number;
};

export type CapacityThresholds = {
    readonly lowThreshold: number;
    readonly mediumThreshold: number;
    readonly highThreshold: number;
};

export type BusFilters = {
    readonly voltages: readonly Voltage[];
    readonly maybeMaxAllocatedCosts: MaybeMaxCosts;
    readonly maybeMaxTotalCosts: MaybeMaxCosts;
    readonly scope: ScopeType;
    readonly hideLockedBuses: boolean;
    readonly includePreExistingConstraints: boolean;
    readonly hideLowConfidenceBuses: boolean;
    readonly capacityThresholds: CapacityThresholds;
};

export type BusFiltersFromQueryParams = {
    readonly voltage?: Voltage;
    readonly scope?: ScopeType;
    readonly maxAllocatedCostsEnabled?: boolean;
    readonly maxAllocatedCosts?: number;
    readonly maxTotalCostsEnabled?: boolean;
    readonly maxTotalCosts?: number;
    readonly includePreExistingConstraints?: boolean;
    readonly hideLowConfidenceBuses?: boolean;
};

export const getDefaultBusFilter = (
    selectedRegion: RegionType,
    selectedGeneratorType: GeneratorType
): BusFilters => {
    const maybeBusFilters =
        REGIONS_TO_DEFAULT_FILTER[selectedRegion][selectedGeneratorType];

    if (maybeBusFilters === undefined) {
        throw Error(
            `Unexpected combination: ${selectedRegion}, ${selectedGeneratorType}`
        );
    }

    return maybeBusFilters;
};

export const getDefaultBusFilterAndOverrideWithParams = (
    selectedRegion: RegionType,
    selectedGeneratorType: GeneratorType,
    queryParams: URLSearchParams
): BusFilters => {
    let busFilters = getDefaultBusFilter(selectedRegion, selectedGeneratorType);

    const busFiltersFromQueryParams = getBusFiltersFromQueryParams(
        selectedRegion,
        queryParams
    );

    // TODO: Support multiple voltages from params
    if (busFiltersFromQueryParams.voltage !== undefined) {
        busFilters = {
            ...busFilters,
            voltages: [busFiltersFromQueryParams.voltage]
        };
    }

    if (busFiltersFromQueryParams.scope !== undefined) {
        busFilters = { ...busFilters, scope: busFiltersFromQueryParams.scope };
    }

    if (busFiltersFromQueryParams.maxAllocatedCostsEnabled !== undefined) {
        busFilters = {
            ...busFilters,
            maybeMaxAllocatedCosts: {
                ...busFilters.maybeMaxAllocatedCosts,
                enabled: busFiltersFromQueryParams.maxAllocatedCostsEnabled
            }
        };
    }

    if (busFiltersFromQueryParams.maxAllocatedCosts !== undefined) {
        busFilters = {
            ...busFilters,
            maybeMaxAllocatedCosts: {
                ...busFilters.maybeMaxAllocatedCosts,
                maxCosts: busFiltersFromQueryParams.maxAllocatedCosts
            }
        };
    }

    if (busFiltersFromQueryParams.maxTotalCostsEnabled !== undefined) {
        busFilters = {
            ...busFilters,
            maybeMaxTotalCosts: {
                ...busFilters.maybeMaxTotalCosts,
                enabled: busFiltersFromQueryParams.maxTotalCostsEnabled
            }
        };
    }

    if (busFiltersFromQueryParams.maxTotalCosts !== undefined) {
        busFilters = {
            ...busFilters,
            maybeMaxTotalCosts: {
                ...busFilters.maybeMaxTotalCosts,
                maxCosts: busFiltersFromQueryParams.maxTotalCosts
            }
        };
    }

    if (busFiltersFromQueryParams.includePreExistingConstraints !== undefined) {
        busFilters = {
            ...busFilters,
            includePreExistingConstraints:
                busFiltersFromQueryParams.includePreExistingConstraints
        };
    }

    // TODO: Support setting hideLowConfidenceBuses from query params
    if (busFiltersFromQueryParams.hideLowConfidenceBuses !== undefined) {
        busFilters = {
            ...busFilters,
            hideLowConfidenceBuses:
                busFiltersFromQueryParams.hideLowConfidenceBuses
        };
    }

    return busFilters;
};
