import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import "./DataFreshnessDialog.scss";

import { Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import React from "react";
import {
    DUKE,
    ERCOT,
    ISONE,
    MISO,
    NYISO,
    PJM,
    RegionType,
    SOCO,
    SPP,
    TVA,
    WECC
} from "types/regionType";
import {
    convertToISONEScopeType,
    convertToMISOScopeType,
    convertToNYISOScopeType,
    convertToPJMScopeType,
    convertToSPPScopeType,
    convertToTVAScopeType,
    convertToWECCScopeType,
    ISONE_2026_SUMMER_PEAK_ALL_QUEUED_GENERATORS_SCOPE_TYPE,
    ISONE_2026_SUMMER_PEAK_SCOPE_TYPE,
    MISO_2022_MTEP_SCOPE_TYPE,
    MISO_2022_REALISTIC_SCOPE_TYPE,
    MISO_2022_SCOPE_TYPE,
    NYISO_2029_SCOPE_TYPE,
    NYISO_CLASS_YEAR_2029_SCOPE_TYPE,
    PJM_2028_LOAD_SCOPE,
    PJM_ALL_QUEUE_SCOPE,
    PJM_ISA_ONLY_SCOPE_TYPE,
    ScopeType,
    SPP_ALL_GENERATORS_SCOPE_TYPE,
    SPP_DISIS_2023_01_PHASE_1_PQ_SCOPE_TYPE,
    TVA_2028_WITHOUT_PRIORS_SCOPE_TYPE,
    WECC_2029_HEAVY_SUMMER_SCOPE_TYPE,
    WECC_ALL_GENERATORS_SCOPE_TYPE,
    WECC_LATE_STAGE_GENERATORS_SCOPE_TYPE
} from "types/scopeType";
import DownloadProjectsCSVComponent from "./utils/DownloadCSVComponent";

type DataFreshnessDialogProps = {
    readonly isOpen: boolean;
    readonly closeDialog: () => void;
};

const DataFreshnessDialog: React.FC<DataFreshnessDialogProps> = (props) => {
    const { isOpen, closeDialog } = props;
    const { region: selectedRegion } = useMapViewRoutingMetadata();
    const {
        busFiltersConfiguration: {
            busFilters: { scope }
        }
    } = useMapViewConfiguration();

    return (
        <Dialog
            isOpen={isOpen}
            title={"Data freshness"}
            onClose={closeDialog}
            icon="time"
        >
            <DialogBody>
                <div>
                    {getComponentBasedOnRegionAndScope(selectedRegion, scope)}
                </div>
            </DialogBody>
            {scope === MISO_2022_REALISTIC_SCOPE_TYPE && (
                <DialogFooter
                    minimal={true}
                    actions={<DownloadProjectsCSVComponent scope={scope} />}
                />
            )}
        </Dialog>
    );
};

const getComponentBasedOnRegionAndScope = (
    selectedRegion: RegionType,
    selectedScope: ScopeType
) => {
    const weccDataFreshnessByUtility = (
        <>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>PACE/PACW:</strong> Queued projects last updated on
                10/22/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>APS:</strong> Queued projects last updated on
                11/08/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>BPA:</strong> Queued projects last updated on
                11/08/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>SRP:</strong> Queued projects last updated on
                11/06/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>PNM:</strong> Queued projects last updated on
                10/25/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>PSCO:</strong> Queued projects last updated on
                10/25/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>TSGT:</strong> Queued projects last updated on
                11/08/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>NWMT:</strong> Queued projects last updated on
                11/08/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>WAPA RM:</strong> Queued projects last updated on
                11/08/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>IPC:</strong> Queued projects last updated on
                11/08/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>NEVP:</strong> Queued projects last updated on
                11/08/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>EPE:</strong> Queued projects last updated on
                11/08/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>WAPA LC:</strong> Queued projects last updated on
                11/08/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>TEP:</strong> Queued projects last updated on
                11/08/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>PGE:</strong> Queued projects last updated on
                10/23/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>AVA:</strong> Queued projects last updated on
                10/18/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>PSE:</strong> Queued projects last updated on
                11/06/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>BHBE:</strong> Queued projects last updated on
                11/08/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>BHCT:</strong> Queued projects last updated on
                11/08/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>CSU:</strong> Queued projects last updated on
                11/13/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>DGT:</strong> Queued projects last updated on
                11/13/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>PRPA:</strong> Queued projects last updated on
                11/13/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>WAPA-UGP:</strong> Queued projects last updated on
                11/13/2024.
            </div>
            <div className="DataFreshnessDialog-subregion-wrapper">
                <strong>AEPCO:</strong> Queued projects last updated on
                11/07/2024.
            </div>
        </>
    );

    if (selectedRegion === PJM) {
        const pjmSelectedScope = convertToPJMScopeType(selectedScope);

        if (pjmSelectedScope === PJM_ALL_QUEUE_SCOPE) {
            return (
                <div>
                    <strong>PJM: </strong>The results shown account for all
                    generators included in PJM's queue as of{" "}
                    <strong>10/23/2024</strong>.
                </div>
            );
        } else if (pjmSelectedScope === PJM_ISA_ONLY_SCOPE_TYPE) {
            return (
                <div>
                    <strong>PJM: </strong>The results shown use the 2023 RTEP
                    Window 2 models and add generators with a firm status in the
                    queue and fast lane projects. Queue status qualifying as
                    firm include in-service, confirmed, engineering and
                    procurement, under construction. Last refreshed:
                    <strong> 10/23/2024</strong>.
                </div>
            );
        } else if (pjmSelectedScope === PJM_2028_LOAD_SCOPE) {
            return (
                <div>
                    <strong>PJM: </strong>Results are based on PJM's 2023 RTEP
                    Window 2 models released on 03/06/2024.
                </div>
            );
        }
    } else if (selectedRegion === MISO) {
        const misoSelectedScope = convertToMISOScopeType(selectedScope);

        if (misoSelectedScope === MISO_2022_SCOPE_TYPE) {
            return (
                <div>
                    <strong>MISO (all subregions): </strong>Results are based
                    off the <strong>DPP-2022 Phase 1 model</strong> released by
                    MISO on 03/15/2024.
                </div>
            );
        } else if (misoSelectedScope === MISO_2022_REALISTIC_SCOPE_TYPE) {
            return (
                <div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        Results reflect the queue as of{" "}
                        <strong>12/02/2024</strong>.
                    </div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        Nira starts with the <strong>MTEP-2022 model</strong>{" "}
                        and then adds generators Nira considers "realistic".
                        Generators are added if they meet the following
                        criteria:
                        <ol>
                            <li>
                                All generators in phase 3 or later are added.
                            </li>
                            <li>
                                Phase 1 or 2 projects with less than $200k / MW
                                interconnection cost are added.
                            </li>
                            <li>
                                For projects with only prescreen results
                                (DPP-2022), we calculated a metric of "# of
                                constraints / MW". We then took the top 14% of
                                projects.
                            </li>
                            <li>
                                For projects without prescreen results
                                (DPP-2023), we used Nira's August 2024 MISO
                                Realistic data calculate each county's average
                                NU allocation for a 200 MW project, ranked the
                                counties, and retained the top 14% of projects
                                within top-ranked counties for each subregion.
                            </li>
                        </ol>
                    </div>
                </div>
            );
        } else if (misoSelectedScope === MISO_2022_MTEP_SCOPE_TYPE) {
            return (
                <div>
                    <strong>MISO (all subregions): </strong>Nira uses MISO's
                    MTEP 2022 Summer Peak model released on 6/7/2023.
                </div>
            );
        }
    } else if (selectedRegion === SPP) {
        const sppSelectedScope = convertToSPPScopeType(selectedScope);

        if (sppSelectedScope === SPP_DISIS_2023_01_PHASE_1_PQ_SCOPE_TYPE) {
            return (
                <div>
                    <strong>SPP: </strong>Results are based on the latest 2023
                    Phase 1 models. DISIS-2023 and prior queues are dispatched
                    as Prior Queue. DISIS-2022 and prior network upgrades are
                    included. The results shown account for all generators
                    withdrawn from SPP's queue as of <strong>12/11/2024</strong>
                    .
                </div>
            );
        } else if (sppSelectedScope === SPP_ALL_GENERATORS_SCOPE_TYPE) {
            return (
                <div>
                    <strong>SPP: </strong>The results shown account for all
                    generators included in SPP's queue as of{" "}
                    <strong>12/11/2024</strong>. DISIS 2021 and prior network
                    upgrades are included.
                </div>
            );
        }
    } else if (selectedRegion === ERCOT) {
        return (
            <div>
                <div className="DataFreshnessDialog-subregion-wrapper">
                    <strong>Discharging - Solar/Storage: </strong>Constraints
                    come from the "2028 summer peak model" released by ERCOT on
                    10/14/2024.
                </div>
                <div className="DataFreshnessDialog-subregion-wrapper">
                    <strong>Discharging - Wind: </strong>Constraints come from
                    the "2028 high renewable minimum load model" released by
                    ERCOT on 10/14/2024.
                </div>
                <div className="DataFreshnessDialog-subregion-wrapper">
                    <strong>Charging: </strong>Constraints come from the "2028
                    high renewable minimum load model" released by ERCOT on
                    10/14/2024.
                </div>
                <div className="DataFreshnessDialog-subregion-wrapper">
                    <strong>Large Load: </strong>Constraints come from the "2028
                    summer peak model" released by ERCOT 10/14/2024.
                </div>
            </div>
        );
    } else if (selectedRegion === WECC) {
        const weccSelectedScope = convertToWECCScopeType(selectedScope);
        if (weccSelectedScope === WECC_2029_HEAVY_SUMMER_SCOPE_TYPE) {
            return (
                <div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        Constraints come from the{" "}
                        <strong>2029 Heavy Summer Model</strong> (discharging)
                        and <strong>2033 Light Load Model</strong> (charging)
                        released by WECC.
                    </div>
                </div>
            );
        } else if (
            weccSelectedScope === WECC_LATE_STAGE_GENERATORS_SCOPE_TYPE
        ) {
            return (
                <div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        Constraints come from the{" "}
                        <strong>2029 Heavy Summer Model</strong> (discharging)
                        and <strong>2033 Light Load Model</strong> (charging)
                        released by WECC, with the addition of all late-stage
                        queued projects.
                    </div>
                    {weccDataFreshnessByUtility}
                </div>
            );
        } else if (weccSelectedScope === WECC_ALL_GENERATORS_SCOPE_TYPE) {
            return (
                <div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        Constraints come from the{" "}
                        <strong>2029 Heavy Summer Model</strong> (discharging)
                        and <strong>2033 Light Load Model</strong> (charging)
                        released by WECC, with the addition of all queued
                        projects.
                    </div>
                    {weccDataFreshnessByUtility}
                </div>
            );
        }
    } else if (selectedRegion === NYISO) {
        const nyisoSelectedScope = convertToNYISOScopeType(selectedScope);
        if (nyisoSelectedScope === NYISO_2029_SCOPE_TYPE) {
            return (
                <div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        <strong>Discharging - Solar/Storage: </strong>
                        Constraints come from the "2029 Summer Peak model"
                        released in NYISO's 2023 FERC 715 model filing.
                    </div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        <strong>Charging: </strong>Constraints come from the
                        "2029 Light Load Model" released in NYISO's 2023 FERC
                        715 model filing.
                    </div>
                </div>
            );
        } else if (nyisoSelectedScope === NYISO_CLASS_YEAR_2029_SCOPE_TYPE) {
            return (
                <div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        <strong>Discharging - Solar/Storage: </strong>
                        Constraints come from the "CY 2023, 2029 Summer Peak
                        ATRA Model"
                    </div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        <strong>Charging: </strong>Constraints come from the "CY
                        2023, 2029 Light Load ATRA Model".
                    </div>
                </div>
            );
        }
    } else if (selectedRegion === TVA) {
        const tvaSelectedScope = convertToTVAScopeType(selectedScope);
        if (tvaSelectedScope == TVA_2028_WITHOUT_PRIORS_SCOPE_TYPE) {
            return (
                <div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        Constraints come from the{" "}
                        <strong>2028 Spring and Summer model</strong> released
                        by TVA.
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        Constraints come from the{" "}
                        <strong>2028 Spring and Summer model</strong> released
                        by TVA.
                    </div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        The results shown account for all generators included in
                        TVA's queue as of <strong>01/09/2025</strong>.{" "}
                    </div>
                </div>
            );
        }
    } else if (selectedRegion === ISONE) {
        const isoneSelectedScope = convertToISONEScopeType(selectedScope);
        if (
            isoneSelectedScope ===
            ISONE_2026_SUMMER_PEAK_ALL_QUEUED_GENERATORS_SCOPE_TYPE
        ) {
            return (
                <div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        Constraints come from the{" "}
                        <strong>2026 Summer Peak model</strong> released by
                        ISO-NE, with appropriate interface stresses applied for
                        each System Planning Subarea.
                    </div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        The results shown account for all generators included in
                        ISO-NE's queue as of <strong>12/11/2024</strong>.{" "}
                    </div>
                </div>
            );
        } else if (isoneSelectedScope === ISONE_2026_SUMMER_PEAK_SCOPE_TYPE) {
            return (
                <div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        Constraints come from the{" "}
                        <strong>2026 Summer Peak model</strong> released by
                        ISO-NE, and adds queued generators that have completed a
                        system impact study or are negotiating/have completed an
                        interconnection agreement by <strong>12/11/2024</strong>
                        .
                    </div>
                    <div className="DataFreshnessDialog-subregion-wrapper">
                        Appropriate interface stresses were applied for each
                        System Planning Subarea.
                    </div>
                </div>
            );
        }
    } else if (selectedRegion === SOCO) {
        return (
            <div>
                <div className="DataFreshnessDialog-subregion-wrapper">
                    Constraints come from SOCO's{" "}
                    <strong>
                        2028 Summer Peak and 2028 Off-peak models with SNWE and
                        NSEW flow directions
                    </strong>
                    .
                </div>
                <div className="DataFreshnessDialog-subregion-wrapper">
                    <strong>SOCO:</strong> Queued projects last updated on
                    09/12/2024.
                </div>
                <div className="DataFreshnessDialog-subregion-wrapper">
                    <strong>GTC:</strong> Queued projects last updated in June
                    2024.
                </div>
            </div>
        );
    } else if (selectedRegion == DUKE) {
        return (
            <div>
                <div className="DataFreshnessDialog-subregion-wrapper">
                    Constraints come from Duke's{" "}
                    <strong>DEP 2023 DISIS Phase 2 models</strong> and
                    <strong> DEC 2023 DISIS Phase 2 models</strong>. Contingent
                    facilities are excluded from the constraints list. Model
                    files are based on Duke's last update as of
                    <strong> 5/31/2024</strong>.
                </div>
            </div>
        );
    }
};

export default DataFreshnessDialog;
