import {
    GeneratorType,
    LOAD_TYPE,
    SOLAR_TYPE,
    STORAGE_TYPE,
    WIND_TYPE
} from "./generatorType";
import { RegionType } from "./regionType";

export const getComponentConfig = (
    selectedRegion: RegionType,
    selectedGeneratorType: GeneratorType
): ComponentConfig => {
    const maybeConfig =
        REGIONS_TO_COMPONENT_CONFIG[selectedRegion][selectedGeneratorType];

    if (maybeConfig === undefined) {
        throw Error(
            `Unexpected combination: ${selectedRegion}, ${selectedGeneratorType}`
        );
    }

    return maybeConfig;
};

// TODO: At some point, we may want separate config objects for the sidebar and the detail pane
export type ComponentConfig = PricedConstraintConfig | CurtailmentConfig;

export type PricedConstraintConfig = {
    readonly showCapacity: boolean;
    readonly hasAllocatedCost: boolean;
    readonly isPreExistingConstraintsEnabled: boolean;
    readonly allowUsersToViewLowConfidenceBuses: boolean;
    readonly showContingency: boolean;
    readonly showLineTaps: boolean;
};

export const isPricedConstraintConfig = (
    type: ComponentConfig
): type is PricedConstraintConfig => {
    return (type as PricedConstraintConfig).showCapacity !== undefined;
};

export type CurtailmentConfig = {
    readonly showModelSwitcherConstraintTable: boolean;
    readonly showModelColumnConstraintTable: boolean;
    readonly allowUsersToViewLowConfidenceBuses: boolean;
    readonly showContingency: boolean;
    readonly showLineTaps: boolean;
};

export const isCurtailmentConfig = (
    type: ComponentConfig
): type is CurtailmentConfig => {
    return (
        (type as CurtailmentConfig).showModelSwitcherConstraintTable !==
        undefined
    );
};

export const convertToCurtailmentConfig = (
    config: ComponentConfig
): CurtailmentConfig => {
    if (isCurtailmentConfig(config)) {
        return config;
    }
    throw new Error("Unexpected config type");
};

const NYISO_CONFIG = {
    showModelSwitcherConstraintTable: false,
    showModelColumnConstraintTable: false,
    allowUsersToViewLowConfidenceBuses: true,
    showContingency: true,
    showLineTaps: true
};
const PJM_CONFIG = {
    showCapacity: true,
    hasAllocatedCost: true,
    isPreExistingConstraintsEnabled: false,
    allowUsersToViewLowConfidenceBuses: true,
    showContingency: true,
    showLineTaps: true
};
const PJM_WIND_CONFIG = {
    showCapacity: true,
    hasAllocatedCost: true,
    isPreExistingConstraintsEnabled: false,
    allowUsersToViewLowConfidenceBuses: true,
    showContingency: true,
    showLineTaps: true
};
const PJM_LOAD_CONFIG = {
    showCapacity: false,
    hasAllocatedCost: false,
    isPreExistingConstraintsEnabled: true,
    allowUsersToViewLowConfidenceBuses: true,
    showContingency: true,
    showLineTaps: true
};
const MISO_CONFIG = {
    showCapacity: true,
    hasAllocatedCost: true,
    isPreExistingConstraintsEnabled: true,
    allowUsersToViewLowConfidenceBuses: true,
    showContingency: true,
    showLineTaps: true
};
const MISO_LOAD_CONFIG = {
    showCapacity: false,
    hasAllocatedCost: false,
    isPreExistingConstraintsEnabled: false,
    allowUsersToViewLowConfidenceBuses: true,
    showContingency: true,
    showLineTaps: false
};

const SPP_CONFIG = {
    showCapacity: true,
    hasAllocatedCost: true,
    isPreExistingConstraintsEnabled: false,
    allowUsersToViewLowConfidenceBuses: true,
    showContingency: true,
    showLineTaps: true
};

const ERCOT_CONFIG = {
    showModelSwitcherConstraintTable: true,
    showModelColumnConstraintTable: true,
    allowUsersToViewLowConfidenceBuses: true,
    showContingency: true,
    showLineTaps: true
};

const ERCOT_LOAD_CONFIG = {
    showCapacity: false,
    hasAllocatedCost: false,
    isPreExistingConstraintsEnabled: true,
    allowUsersToViewLowConfidenceBuses: true,
    showContingency: true,
    showLineTaps: false
};

const WECC_CONFIG = {
    showCapacity: false,
    hasAllocatedCost: true,
    isPreExistingConstraintsEnabled: false,
    allowUsersToViewLowConfidenceBuses: true,
    showContingency: true,
    showLineTaps: true
};

const TVA_CONFIG = {
    showCapacity: false,
    hasAllocatedCost: false,
    isPreExistingConstraintsEnabled: false,
    allowUsersToViewLowConfidenceBuses: true,
    showContingency: true,
    showLineTaps: true
};

const ISONE_CONFIG = {
    showCapacity: false,
    hasAllocatedCost: false,
    isPreExistingConstraintsEnabled: false,
    allowUsersToViewLowConfidenceBuses: true,
    showContingency: true,
    showLineTaps: true
};

const SOCO_CONFIG = {
    showCapacity: true,
    hasAllocatedCost: true,
    isPreExistingConstraintsEnabled: false,
    allowUsersToViewLowConfidenceBuses: true,
    showContingency: true,
    showLineTaps: true
};

const DUKE_CONFIG = {
    showCapacity: false,
    hasAllocatedCost: true,
    isPreExistingConstraintsEnabled: false,
    allowUsersToViewLowConfidenceBuses: true,
    showContingency: true,
    showLineTaps: true
};

const REGIONS_TO_COMPONENT_CONFIG: {
    readonly [key in RegionType]: {
        readonly [key in GeneratorType]: ComponentConfig | undefined;
    };
} = {
    NYISO: {
        [SOLAR_TYPE]: NYISO_CONFIG,
        [WIND_TYPE]: undefined,
        [STORAGE_TYPE]: NYISO_CONFIG,
        [LOAD_TYPE]: undefined
    },
    PJM: {
        [SOLAR_TYPE]: PJM_CONFIG,
        [WIND_TYPE]: PJM_WIND_CONFIG,
        [STORAGE_TYPE]: undefined,
        [LOAD_TYPE]: PJM_LOAD_CONFIG
    },
    MISO: {
        [SOLAR_TYPE]: MISO_CONFIG,
        [WIND_TYPE]: MISO_CONFIG,
        [STORAGE_TYPE]: MISO_CONFIG,
        [LOAD_TYPE]: MISO_LOAD_CONFIG
    },
    CAISO: {
        [SOLAR_TYPE]: undefined,
        [WIND_TYPE]: undefined,
        [STORAGE_TYPE]: undefined,
        [LOAD_TYPE]: undefined
    },
    SPP: {
        [SOLAR_TYPE]: SPP_CONFIG,
        [WIND_TYPE]: SPP_CONFIG,
        [STORAGE_TYPE]: undefined,
        [LOAD_TYPE]: undefined
    },
    ERCOT: {
        [SOLAR_TYPE]: ERCOT_CONFIG,
        [WIND_TYPE]: ERCOT_CONFIG,
        [STORAGE_TYPE]: ERCOT_CONFIG,
        [LOAD_TYPE]: ERCOT_LOAD_CONFIG
    },
    WECC: {
        [SOLAR_TYPE]: WECC_CONFIG,
        [WIND_TYPE]: undefined,
        [STORAGE_TYPE]: WECC_CONFIG,
        [LOAD_TYPE]: undefined
    },
    TVA: {
        [SOLAR_TYPE]: TVA_CONFIG,
        [WIND_TYPE]: undefined,
        [STORAGE_TYPE]: undefined,
        [LOAD_TYPE]: undefined
    },
    ISONE: {
        [SOLAR_TYPE]: ISONE_CONFIG,
        [WIND_TYPE]: undefined,
        [STORAGE_TYPE]: ISONE_CONFIG,
        [LOAD_TYPE]: undefined
    },
    SOCO: {
        [SOLAR_TYPE]: SOCO_CONFIG,
        [WIND_TYPE]: undefined,
        [STORAGE_TYPE]: undefined,
        [LOAD_TYPE]: undefined
    },
    DUKE: {
        [SOLAR_TYPE]: DUKE_CONFIG,
        [WIND_TYPE]: undefined,
        [STORAGE_TYPE]: DUKE_CONFIG,
        [LOAD_TYPE]: undefined
    }
};
