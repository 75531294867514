import { AgGridReact } from "ag-grid-react";
import { useCluster, useRegionConfig } from "in_queue/contexts/ClusterContext";
import { useAllProjectsInCluster } from "in_queue/contexts/ProjectDataContext";
import {
    useCurrentProjectId,
    useCurrentTrackedProject
} from "in_queue/contexts/ScenarioPageContext";
import { useScenarioResults } from "in_queue/contexts/ScenarioResultsContext";
import { getStudyGroupLabel, StudyGroup } from "in_queue/types/clusterType";
import { InQueueRegionConfig } from "in_queue/types/configPerRegion";
import { ScenarioProject } from "in_queue/types/scenarioResultType";
import { keyBy, mapValues } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Loading, mapLoadingState } from "types/loadingType";
import { RegionType } from "types/regionType";
import {
    AgGridColumnDefs,
    currencyFormatter,
    hideUnwantedColumns,
    roundingFormatter,
    ScenarioProjectsTableColumnId
} from "./agGrid/agGridHelpers";
import { BaseAgGrid } from "./base/BaseAgGrid";
import { ExportButton } from "./ribbon/ExportButton";
import { TableActionRibbon } from "./ribbon/TableActionRibbon";
import { ViewType, ViewTypesMenu } from "./ribbon/ViewTypesMenu";
import css from "./TableStyles.module.scss";

type ProjectStudyGroupMapping = { [projectId: string]: StudyGroup };
const useProjectSubregions = (): Loading<ProjectStudyGroupMapping> => {
    const projects = useAllProjectsInCluster();
    return mapLoadingState(projects, (projects) => {
        return mapValues(
            keyBy(projects, "projectId"),
            (project) => project.cluster.studyGroup
        );
    });
};

export const ScenarioProjectsTable: React.FC<{ filterText: string }> = ({
    filterText
}) => {
    const {
        cluster: { studyGroup }
    } = useCurrentTrackedProject();

    const gridRef = useRef<AgGridReact<ScenarioProject>>(null);

    const { region } = useCluster();
    const scenarioResults = useScenarioResults();
    const componentConfig = useRegionConfig();

    const projectSubregions = useProjectSubregions();
    const currentProjectId = useCurrentProjectId();
    const columnDefs = useMemo(
        () =>
            getColumnDefs(
                region,
                currentProjectId,
                projectSubregions,
                componentConfig
            ),
        [currentProjectId, componentConfig]
    );

    useEffect(() => {
        if (
            !componentConfig.scenarioProjectsTable.columnsToRemove.includes(
                "subregion"
            )
        ) {
            if (gridRef && gridRef.current && gridRef.current.api) {
                gridRef.current.api.setFilterModel({
                    subregion: {
                        filterType: "text",
                        type: "contains",
                        filter: studyGroup.toLowerCase()
                    }
                });
                gridRef.current.api.onFilterChanged();
            }
        }
    }, [gridRef?.current?.api]);

    const [viewType, setViewType] = useState<ViewType>("related");
    const leftActions = [
        <ViewTypesMenu
            currentViewType={viewType}
            setCurrentViewType={setViewType}
            validViewTypes={["related", "all"]}
        />
    ];
    const rightActions = [
        <ExportButton gridRef={gridRef} exportName="Projects" />
    ];

    const projects = mapLoadingState(scenarioResults, (d) => d.projects);
    return (
        <div className={css["table-ribbon-wrapper"]}>
            <TableActionRibbon
                leftActions={leftActions}
                rightActions={rightActions}
            />
            <BaseAgGrid<ScenarioProject>
                ref={gridRef}
                loadingData={projects}
                columnDefs={columnDefs}
                quickFilterText={filterText}
                isExternalFilterPresent={() => viewType !== "all"}
                doesExternalFilterPass={(node) => {
                    if (
                        viewType !== "related" ||
                        scenarioResults === "loading"
                    ) {
                        return true;
                    } else {
                        const relatedProjectIds =
                            scenarioResults.relatedProjectIds;
                        return (
                            node.data != null &&
                            relatedProjectIds.has(node.data.projectId)
                        );
                    }
                }}
            />
        </div>
    );
};

const getColumnDefs = (
    region: RegionType,
    currentProjectId: string,
    projectSubregions: Loading<ProjectStudyGroupMapping>,
    config: InQueueRegionConfig
) => {
    const columnDefs: AgGridColumnDefs<
        ScenarioProject,
        ScenarioProjectsTableColumnId
    > = [
        {
            field: "projectId",
            headerName: "Project",
            width: 90,
            pinned: true,
            filter: true,
            colId: "projectId"
        },
        {
            headerName: "Subregion",
            colId: "subregion",
            width: 125,
            pinned: true,
            filter: true,
            valueGetter: ({ data }) => {
                if (
                    !data ||
                    projectSubregions === "loading" ||
                    !projectSubregions[data.projectId]
                ) {
                    return "-";
                }
                return getStudyGroupLabel(
                    region,
                    projectSubregions[data.projectId]
                );
            }
        },
        {
            field: "numSharedUpgrades",
            headerName: "# shared upgrades",
            headerTooltip: `The number of network upgrades common between each project and ${currentProjectId}.`,
            wrapHeaderText: true,
            width: 100,
            colId: "numSharedUpgrades"
        },
        {
            field: "costPerMw",
            headerName: "$ / MW",
            sort: "desc",
            width: 100,
            valueFormatter: currencyFormatter,
            colId: "costPerMW"
        },
        {
            field: "sumAllocatedCost",
            headerName: "Allocated cost",
            width: 140,
            valueFormatter: currencyFormatter,
            colId: "sumAllocatedCost"
        },
        {
            headerName: "Size (MW)",
            children: [
                {
                    field: "erisSizeMw",
                    headerName: config.sizeLabel.energyLabel,
                    width: 80,
                    colId: "erisSize",
                    valueFormatter: roundingFormatter
                },
                {
                    field: "nrisSizeMw",
                    headerName: config.sizeLabel.capacityLabel,
                    width: 80,
                    colId: "nrisSize",
                    valueFormatter: roundingFormatter
                }
            ]
        },
        {
            headerName: "Cost by type",
            children: [
                {
                    field: "sumAllocatedCostByConstraintType.ERIS",
                    headerName: "ERIS Thermal",
                    width: 120,
                    valueFormatter: currencyFormatter,
                    colId: "erisThermal"
                },
                {
                    field: "sumAllocatedCostByConstraintType.ERIS_LPC",
                    headerName: "ERIS LPC",
                    width: 120,
                    valueFormatter: currencyFormatter,
                    colId: "erisLpc"
                },
                {
                    field: "sumAllocatedCostByConstraintType.ERIS_COMPLEX",
                    headerName: "ERIS Complex",
                    width: 120,
                    valueFormatter: currencyFormatter,
                    colId: "erisComplex"
                },
                {
                    field: "sumAllocatedCostByConstraintType.NRIS",
                    headerName: "NRIS Thermal",
                    width: 120,
                    valueFormatter: currencyFormatter,
                    colId: "nrisThermal"
                },
                {
                    field: "sumAllocatedCostByConstraintType.NRIS_COMPLEX",
                    headerName: "NRIS Complex",
                    width: 120,
                    valueFormatter: currencyFormatter,
                    colId: "nrisComplex"
                },
                {
                    field: "sumAllocatedCostByConstraintType.SHARED_NETWORK_UPGRADE",
                    headerName: "ERIS SNU",
                    width: 120,
                    valueFormatter: currencyFormatter,
                    colId: "erisSnu"
                }
            ]
        },
        {
            field: "assignedUpgrades",
            headerName: "All assigned upgrades",
            width: 400,
            colId: "assignedUpgrades"
        }
    ];

    hideUnwantedColumns(
        columnDefs,
        config.scenarioProjectsTable.columnsToRemove
    );

    return columnDefs;
};
