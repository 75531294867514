import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Icon,
    Intent,
    Menu,
    MenuItem,
    OverlayToaster,
    Popover,
    Position
} from "@blueprintjs/core";
import {
    segmentTrackScreeningViewCopyLink,
    segmentTrackScreeningViewDelete,
    segmentTrackScreeningViewExport
} from "analytics/analyticTrackEvents";

import RegionSelectorDialog from "components/regionSelector/RegionSelectorDialog";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { useScreeningViewContext } from "contexts/ScreeningViewContext/ScreeningViewContext";
import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import {
    ArchiveScreeningViewMutation,
    ArchiveScreeningViewMutationVariables
} from "generated/graphql";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRedirectUrl, getRegionToSpaceDict } from "types/gitBook";
import { ADMIN_TYPE } from "types/roleType";
import { getUrlForScreeningView, ScreeningView } from "types/screeningViewType";
import DataFreshnessDialog from "./DataFreshnessDialog";
import {
    ExportFilterType,
    ExportType,
    EXPORT_FILTER_ALL,
    EXPORT_FILTER_HIGH_CAPACITY,
    KMZ_TYPE,
    SHAPEFILE_TYPE,
    useExportScreeningView
} from "./exports/exportHelpers";
import PlanDetailsDialog from "./PlanDetailsDialog";
import AboutScreeningViewDialog from "./screeningView/AboutScreeningViewDialog";
import CreateScreeningViewDialog from "./screeningView/CreateScreeningViewDialog";
import "./SidebarMenu.scss";

const ARCHIVE_SCREENING_VIEW = loader(
    "src/graphql/archiveScreeningView.graphql"
);
const ScreeningViewToaster = OverlayToaster.create({
    position: Position.TOP
});

enum DialogTypes {
    REGION_SELECTOR = "REGION_SELECTOR",
    DATA_FRESHNESS = "DATA_FRESHNESS",
    PLAN_DETAILS = "PLAN_DETAILS",
    CREATE_SCREENING_VIEW = "CREATE_SCREENING_VIEW",
    ABOUT_SCREENING_VIEW = "ABOUT_SCREENING_VIEW",
    NONE = "NONE"
}

const DIALOG_COMPONENTS = {
    [DialogTypes.REGION_SELECTOR]: RegionSelectorDialog,
    [DialogTypes.DATA_FRESHNESS]: DataFreshnessDialog,
    [DialogTypes.PLAN_DETAILS]: PlanDetailsDialog,
    [DialogTypes.CREATE_SCREENING_VIEW]: CreateScreeningViewDialog,
    [DialogTypes.ABOUT_SCREENING_VIEW]: AboutScreeningViewDialog
};

const SidebarMenu: React.FC = () => {
    const [archiveScreeningView] = useMutation<
        ArchiveScreeningViewMutation,
        ArchiveScreeningViewMutationVariables
    >(ARCHIVE_SCREENING_VIEW);
    const exportScreeningViewToKmz = useExportScreeningView(KMZ_TYPE);
    const exportScreeningViewToShapefile =
        useExportScreeningView(SHAPEFILE_TYPE);

    const navigate = useNavigate();
    const { region, generator } = useMapViewRoutingMetadata();
    const maybeScreeningViewData = useScreeningViewContext();
    const { logout } = useAuth0();
    const user = useUserDataContext();

    const [currentDialog, setCurrentDialog] = useState<DialogTypes>(
        DialogTypes.NONE
    );

    const openDialog = (type: DialogTypes) => setCurrentDialog(type);
    const closeDialog = () => setCurrentDialog(DialogTypes.NONE);

    const maybeRegionMethodologySpace = getRegionToSpaceDict()[region];
    // This will be undefined if the region is not supported yet or if the user
    // doesn't have access to the region (which should never happen if they get to this page).
    const maybeRegionMethodologyLink =
        maybeRegionMethodologySpace === undefined
            ? undefined
            : getRedirectUrl(maybeRegionMethodologySpace, null, user);

    const onClickExport = async ({
        screeningViewData,
        exportType,
        busFilterType
    }: {
        screeningViewData: ScreeningView;
        exportType: ExportType;
        busFilterType: ExportFilterType;
    }) => {
        ScreeningViewToaster.show({
            message: "Export in progress.",
            intent: Intent.PRIMARY
        });

        const exportTypeText =
            exportType === SHAPEFILE_TYPE ? "Shapefile" : "KMZ";
        try {
            const exportFunc =
                exportType === SHAPEFILE_TYPE
                    ? exportScreeningViewToShapefile
                    : exportScreeningViewToKmz;
            await exportFunc(busFilterType);
        } catch (error) {
            ScreeningViewToaster.show({
                message: `Encountered an error while exporting ${exportTypeText}.`,
                intent: Intent.DANGER
            });
            return;
        }

        ScreeningViewToaster.show({
            message: `Exported as ${exportTypeText}.`,
            intent: Intent.SUCCESS
        });
        segmentTrackScreeningViewExport(screeningViewData.id, exportType);
    };

    return (
        <>
            <Popover
                content={
                    <Menu>
                        <MenuItem
                            icon="time"
                            text="Data freshness"
                            onClick={() =>
                                openDialog(DialogTypes.DATA_FRESHNESS)
                            }
                        />
                        <MenuItem
                            icon="globe"
                            text={"Change region or view"}
                            onClick={() =>
                                openDialog(DialogTypes.REGION_SELECTOR)
                            }
                        />
                        <MenuItem
                            icon="credit-card"
                            text="View plan"
                            onClick={() => openDialog(DialogTypes.PLAN_DETAILS)}
                        />
                        {maybeScreeningViewData && (
                            <MenuItem icon="map" text="Screening view">
                                <MenuItem
                                    icon="info-sign"
                                    text={"About"}
                                    onClick={() =>
                                        openDialog(
                                            DialogTypes.ABOUT_SCREENING_VIEW
                                        )
                                    }
                                />
                                <MenuItem
                                    icon="share"
                                    text={"Copy link"}
                                    onClick={async () => {
                                        const maybeUrl = getUrlForScreeningView(
                                            maybeScreeningViewData
                                        );
                                        if (maybeUrl) {
                                            await navigator.clipboard.writeText(
                                                `${window.location.origin}${maybeUrl}`
                                            );
                                            ScreeningViewToaster.show({
                                                message:
                                                    "Copied screening view link to clipboard.",
                                                intent: Intent.PRIMARY
                                            });
                                            segmentTrackScreeningViewCopyLink(
                                                maybeScreeningViewData.id
                                            );
                                        }
                                    }}
                                />
                                <MenuItem icon="export" text={"Export"}>
                                    <MenuItem
                                        icon="area-of-interest"
                                        text="KMZ (all)"
                                        onClick={async () =>
                                            onClickExport({
                                                screeningViewData:
                                                    maybeScreeningViewData,
                                                exportType: KMZ_TYPE,
                                                busFilterType: EXPORT_FILTER_ALL
                                            })
                                        }
                                    />
                                    <MenuItem
                                        icon="area-of-interest"
                                        text="KMZ (green)"
                                        onClick={async () =>
                                            onClickExport({
                                                screeningViewData:
                                                    maybeScreeningViewData,
                                                exportType: KMZ_TYPE,
                                                busFilterType:
                                                    EXPORT_FILTER_HIGH_CAPACITY
                                            })
                                        }
                                    />
                                    <MenuItem
                                        icon="shapes"
                                        text="Shapefile (all)"
                                        onClick={async () =>
                                            onClickExport({
                                                screeningViewData:
                                                    maybeScreeningViewData,
                                                exportType: SHAPEFILE_TYPE,
                                                busFilterType: EXPORT_FILTER_ALL
                                            })
                                        }
                                    />
                                    <MenuItem
                                        icon="shapes"
                                        text="Shapefile (green)"
                                        onClick={async () =>
                                            onClickExport({
                                                screeningViewData:
                                                    maybeScreeningViewData,
                                                exportType: SHAPEFILE_TYPE,
                                                busFilterType:
                                                    EXPORT_FILTER_HIGH_CAPACITY
                                            })
                                        }
                                    />
                                </MenuItem>
                                {user.role === ADMIN_TYPE && (
                                    <MenuItem
                                        icon="trash"
                                        text={"Delete"}
                                        onClick={() => {
                                            ScreeningViewToaster.show({
                                                message:
                                                    "Are you sure you want to delete this screening view?" +
                                                    "You'll be taken back to the home page once deleted.",
                                                intent: Intent.DANGER,
                                                action: {
                                                    icon: "trash",
                                                    text: "Delete",
                                                    onClick: () => {
                                                        archiveScreeningView({
                                                            variables: {
                                                                id: maybeScreeningViewData.id
                                                            },
                                                            onCompleted: () => {
                                                                segmentTrackScreeningViewDelete(
                                                                    maybeScreeningViewData.id
                                                                );
                                                                navigate("/");
                                                            }
                                                        });
                                                    }
                                                },
                                                timeout: undefined
                                            });
                                        }}
                                    />
                                )}
                            </MenuItem>
                        )}
                        {maybeScreeningViewData === undefined &&
                            user.role === ADMIN_TYPE && (
                                <MenuItem
                                    icon="map-create"
                                    text="New screening view"
                                    onClick={() =>
                                        openDialog(
                                            DialogTypes.CREATE_SCREENING_VIEW
                                        )
                                    }
                                />
                            )}
                        <MenuItem
                            icon="manual"
                            text={`Documentation${
                                maybeRegionMethodologySpace === undefined
                                    ? " (coming soon)"
                                    : ""
                            }`}
                            disabled={!maybeRegionMethodologyLink}
                            onClick={() =>
                                maybeRegionMethodologyLink &&
                                window.open(
                                    maybeRegionMethodologyLink,
                                    "_blank"
                                )
                            }
                        />
                        <MenuItem
                            icon="log-out"
                            text="Logout"
                            onClick={() =>
                                logout({ returnTo: window.location.origin })
                            }
                        />
                    </Menu>
                }
                position={Position.RIGHT_BOTTOM}
                minimal={true}
            >
                <Icon
                    className={"bp5-text-muted SidebarMenu-settings-icon"}
                    icon="cog"
                />
            </Popover>

            {Object.entries(DIALOG_COMPONENTS).map(
                ([dialogType, DialogComponent]) => (
                    <DialogComponent
                        key={dialogType}
                        isOpen={currentDialog === dialogType}
                        closeDialog={closeDialog}
                        currentRegionAndGeneratorType={{
                            region,
                            generatorType: generator
                        }}
                        currentScreeningView={maybeScreeningViewData}
                    />
                )
            )}
        </>
    );
};

export default SidebarMenu;
