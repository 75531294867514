import {
    Constraint_Types_Enum,
    GetScenarioResultsQuery
} from "generated/graphql";
import { convertMaybeToUndefined } from "graphql/helpers/queryHelpers";
import { Project } from "./projectType";

export interface ScenarioResults {
    constraints: ScenarioConstraint[];
    allocatedUpgrades: ScenarioAllocatedUpgrade[];
    projects: ScenarioProject[];
    relatedProjectIds: Set<string>;
}

// Re-exports the Hasura-generated status types under a different name
// This enables us to change how this is structured in the future if we
// want to decouple from the Hasura typings.
export type ConstraintType = Constraint_Types_Enum;
export const ConstraintType = Constraint_Types_Enum;

export interface ScenarioConstraint {
    constraintType: ConstraintType;
    monitoredFacilityName: string;
    worstContingencyName?: string;
    preStudyLoad?: number;
    postStudyLoad?: number;
    preStudyFlow?: number;
    postStudyFlow?: string;
    preStudyRating?: number;
    postStudyRating?: number;
    networkUpgrades: string[];
    projectId: string;
    dfax: number;
    mwImpact: number;
    notes?: string;

    // These fields are not filled in everywhere yet
    elementType?: string;
    lineLength?: number;
    voltage?: string;
    // The facility details is a summary of the three fields above
    facilityDetails?: string;
}

export interface ScenarioAllocatedUpgrade {
    constraintType: ConstraintType;
    networkUpgradeId: string;
    networkUpgradeName: string;
    projectId: string;
    allocatedCost: number;
    maxDfax: number;
    sumMwImpact: number;
    totalCost: number;
    monitoredFacilityNames: string[];
    notes?: string;
    percentAllocated?: number;
}
export interface ScenarioProject {
    projectId: string;
    erisSizeMw: number;
    nrisSizeMw: number;
    costPerMw: number | undefined;
    sumAllocatedCost: number;
    assignedUpgrades: string[];
    overloadedFacilities: string[];
    sumAllocatedCostByConstraintType: { [constraintType: string]: number };

    // Derived field
    numSharedUpgrades: number;
}

type HasuraQueryResult = GetScenarioResultsQuery["scenarios"][0];
type HasuraConstraint = HasuraQueryResult["scenario_constraints"][0];
type HasuraAllocatedUpgrade =
    HasuraQueryResult["scenario_allocated_upgrades"][0];
type HasuraScenarioProject = HasuraQueryResult["scenario_projects"][0];

export const convertHasuraScenarioDataToScenarioResults = (
    hasuraResult: GetScenarioResultsQuery,
    currentProject: Project
): ScenarioResults => {
    if (hasuraResult.scenarios.length !== 1) {
        throw new Error("Couldn't find single scenario result for id");
    }
    const scenario = hasuraResult.scenarios[0];
    const scenarioProjects = convertHasuraScenarioProjectsToScenarioProjects(
        scenario.scenario_projects,
        currentProject
    );
    const relatedProjectIds = scenarioProjects
        .filter((sp) => sp.numSharedUpgrades > 0)
        .map((sp) => sp.projectId);
    const allocatedUpgrades = convertHasuraAllocatedUpgradesToUpgrades(
        scenario.scenario_allocated_upgrades
    );
    return {
        constraints: convertHasuraConstraintsToConstraints(
            scenario.scenario_constraints
        ),
        allocatedUpgrades,
        projects: scenarioProjects,
        relatedProjectIds: new Set([
            ...relatedProjectIds,
            currentProject.projectId
        ])
    };
};

const convertHasuraConstraintsToConstraints = (
    hasuraConstraints: HasuraConstraint[]
): ScenarioConstraint[] => {
    return hasuraConstraints.map((c) => {
        let facilityDetails;
        if (c.element_type) {
            facilityDetails =
                c.element_type === "transformer"
                    ? `${c.voltage}kV transformer`
                    : `${c.line_length?.toFixed(1)}-mile ${c.voltage}kV line`;
        }

        return {
            ...hasuraConstraintToConstraint(c),
            facilityDetails
        };
    });
};

const hasuraConstraintToConstraint = (
    c: HasuraConstraint
): ScenarioConstraint => {
    return {
        constraintType: c.constraint_type,
        monitoredFacilityName: c.monitored_facility_name,
        elementType: c.element_type ?? undefined,
        lineLength: c.line_length ?? undefined,
        voltage: c.voltage ?? undefined,
        worstContingencyName: c.worst_contingency_name ?? undefined,
        preStudyLoad: c.pre_study_load ?? undefined,
        postStudyLoad: c.post_study_load ?? undefined,
        preStudyFlow: c.pre_study_flow ?? undefined,
        postStudyFlow: c.post_study_flow ?? undefined,
        preStudyRating: c.pre_study_rating ?? undefined,
        postStudyRating: c.post_study_rating ?? undefined,
        networkUpgrades: c.network_upgrade_names,
        projectId: c.project_id,
        dfax: c.dfax,
        mwImpact: c.mw_impact,
        notes: convertMaybeToUndefined(c.notes)
    };
};

const convertHasuraAllocatedUpgradesToUpgrades = (
    hasuraUpgrades: HasuraAllocatedUpgrade[]
): ScenarioAllocatedUpgrade[] => {
    return hasuraUpgrades.map((u) => ({
        constraintType: u.constraint_type,
        networkUpgradeId: u.network_upgrade_id,
        networkUpgradeName: u.network_upgrade_name,
        projectId: u.project_id,
        allocatedCost: u.allocated_cost,
        maxDfax: u.max_dfax,
        sumMwImpact: u.sum_mw_impact,
        totalCost: u.total_cost,
        percentAllocated: u.allocated_cost / u.total_cost,
        monitoredFacilityNames: u.monitored_facility_names,
        notes: convertMaybeToUndefined(u.notes)
    }));
};

type ConvertedHasuraProject = Omit<ScenarioProject, "numSharedUpgrades">;

const convertHasuraScenarioProjectsToScenarioProjects = (
    hasuraProjects: HasuraScenarioProject[],
    currentProject: Project
): ScenarioProject[] => {
    let currentProjectUpgrades: Set<string> = new Set();
    const convertedProjects: ConvertedHasuraProject[] = [];
    for (const p of hasuraProjects) {
        if (p.project_id === currentProject.projectId) {
            currentProjectUpgrades = new Set(p.assigned_upgrades);
        }
        convertedProjects.push({
            projectId: p.project_id,
            erisSizeMw: p.eris_size_mw,
            nrisSizeMw: p.nris_size_mw,
            costPerMw: Number.isFinite(p.cost_per_mw)
                ? p.cost_per_mw
                : undefined,
            sumAllocatedCost: p.sum_allocated_cost,
            assignedUpgrades: p.assigned_upgrades,
            overloadedFacilities: p.overloaded_facilities,
            sumAllocatedCostByConstraintType:
                p.sum_allocated_cost_by_constraint_type ?? {}
        });
    }

    const results = convertedProjects.map((p) => {
        const numSharedUpgrades = p.assignedUpgrades.filter((upgrade) =>
            currentProjectUpgrades.has(upgrade)
        ).length;

        return {
            ...p,
            numSharedUpgrades
        };
    });

    return results;
};
