/* eslint-disable @typescript-eslint/no-explicit-any */
// Haven't been able to find any good typings for cell renderers

import {
    ColGroupDef,
    ICellRendererParams,
    ValueGetterFunc
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
    useRegionConfig,
    useStageConfig
} from "in_queue/contexts/ClusterContext";
import { InQueueRegionConfig } from "in_queue/types/configPerRegion";
import { TrackedProject } from "in_queue/types/projectType";
import {
    ScenarioMetrics,
    ScenarioMetricsMapping
} from "in_queue/types/scenarioMetricsType";
import {
    ScenarioMetadata,
    ScenarioStage,
    ScenarioStatus
} from "in_queue/types/scenarioType";
import { reduceProjectSizeByPercentage } from "in_queue/types/scenarioUtils";
import { formatRelativeDate } from "in_queue/utils/formatting";
import { RefObject } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "types/loadingType";
import {
    AgGridColumnDefs,
    currencyFormatterHelper,
    hideUnwantedColumns,
    NiraColDef,
    roundingFormatter,
    ScenariosTableColumnId,
    statusFormatter,
    stringFormatter,
    timingFormatter,
    titleCaseStringFormatter
} from "./agGrid/agGridHelpers";
import { BaseAgGrid } from "./base/BaseAgGrid";
import css from "./TableStyles.module.scss";

const ScenarioCellRenderer: any = (
    params: ICellRendererParams<ScenarioMetadata>
) => {
    const scenarioId = params.data?.scenarioId;
    return (
        <Link to={`scenario/${scenarioId}` ?? ""} className={css["link"]}>
            {params.data?.title}
        </Link>
    );
};

const NoneCell = () => <span className={css["muted-cell"]}>None</span>;

const SensitivitiesCellRenderer: any = (
    params: ICellRendererParams<ScenarioMetadata>
) => {
    const scenario = params.data;
    if (!scenario) {
        return <NoneCell />;
    }

    if (Object.keys(params.value).length === 0) {
        return <NoneCell />;
    }

    return <span>{params.valueFormatted}</span>;
};

const CurrencyCellRenderer: any = (
    params: ICellRendererParams<ScenarioMetadata>
) => {
    return <span>{currencyFormatterHelper(params.value)}</span>;
};

const TimingCellRenderer: any = (
    params: ICellRendererParams<ScenarioMetadata>
) => {
    const scenario = params.data;
    if (!scenario) {
        return "-";
    }

    const timing = scenario.timeMetadata;

    let formattedTime = "-";
    if (scenario.status === ScenarioStatus.Draft && timing.createdAt) {
        formattedTime = `Created ${formatRelativeDate(timing.createdAt)}`;
    } else if (
        scenario.status === ScenarioStatus.RunFailed &&
        timing.runStartedAt
    ) {
        formattedTime = `Started ${formatRelativeDate(timing.runStartedAt)}`;
    } else if (
        (scenario.status === ScenarioStatus.RunSucceeded ||
            scenario.status === ScenarioStatus.Archived) &&
        timing.runCompletedAt
    ) {
        formattedTime = `Ran ${formatRelativeDate(timing.runCompletedAt)}`;
    } else if (
        scenario.status === ScenarioStatus.RunFailed &&
        timing.runCompletedAt
    ) {
        formattedTime = `Failed ${formatRelativeDate(timing.runCompletedAt)}`;
    }

    return <span>{formattedTime}</span>;
};

const getColumnDefs = ({
    project,
    scenarioMetrics,
    config,
    scenarioStage
}: {
    project: Loading<TrackedProject | undefined>;
    scenarioMetrics: Loading<ScenarioMetricsMapping>;
    config: InQueueRegionConfig;
    scenarioStage: ScenarioStage;
}): AgGridColumnDefs<ScenarioMetadata, ScenariosTableColumnId> => {
    const stageConfig = useStageConfig();

    const columnDefs: AgGridColumnDefs<
        ScenarioMetadata,
        ScenariosTableColumnId
    > = [
        {
            colId: "title",
            field: "title",
            headerName: "Scenario",
            width: 300,
            cellRenderer: ScenarioCellRenderer
        },
        getMetricsColumns(scenarioMetrics),
        {
            headerName: "Assumptions",
            children: [
                ...getProjectSizeColumns(project, config),
                {
                    colId: "sensitivities",
                    field: "assumptions",
                    headerName: "Sensitivities",
                    width: 220,
                    valueFormatter: (params) =>
                        Object.keys(params.value).join(", "),
                    cellRenderer: SensitivitiesCellRenderer
                }
            ]
        },
        {
            headerName: "Scenario run",
            children: [
                {
                    colId: "runStatus",
                    field: "status",
                    headerName: "Status",
                    width: 75,
                    valueFormatter: statusFormatter,
                    enableCellChangeFlash: true
                },
                {
                    colId: "timing",
                    field: "timeMetadata.latestDate",
                    headerName: "Timing",
                    valueFormatter: timingFormatter,
                    cellRenderer: TimingCellRenderer,
                    minWidth: 120,
                    flex: 1
                }
            ]
        },
        {
            colId: "inputData",
            headerName: "Input data",
            field: "inputDataUsed.concise",
            width: 200,
            valueFormatter: stringFormatter
        }
    ];
    if (
        stageConfig.configurableCostEstimatesForPreview &&
        scenarioStage == "PREVIEW"
    ) {
        columnDefs.push({
            colId: "costAssumptions",
            headerName: "Cost assumptions",
            field: "costAssumptions.type",
            valueFormatter: titleCaseStringFormatter,
            width: 140
        });
    }

    hideUnwantedColumns(columnDefs, config.scenariosTable.columnsToRemove);

    return columnDefs;
};

const getProjectSizeColumns = (
    project: Loading<TrackedProject | undefined>,
    config: InQueueRegionConfig
): NiraColDef<ScenarioMetadata, ScenariosTableColumnId>[] => {
    const getModifiedSize = (data: ScenarioMetadata | undefined) => {
        if (project === "loading" || project === undefined || !data) {
            return undefined;
        }
        if (!(project.projectId in data.assumptions)) {
            return project.size;
        }
        return reduceProjectSizeByPercentage(
            project.size,
            data.assumptions[project.projectId]
        );
    };
    return [
        {
            colId: "energyMw",
            headerName: config.sizeLabel.energyLabel,
            width: 80,
            valueGetter: ({ data }) => getModifiedSize(data)?.erisMw ?? "-",
            valueFormatter: roundingFormatter
        },
        {
            colId: "capacityMw",
            headerName: config.sizeLabel.capacityLabel,
            width: 80,
            valueGetter: ({ data }) => getModifiedSize(data)?.nrisMw ?? "-",
            valueFormatter: roundingFormatter
        }
    ];
};

const getMetricsColumns = (
    scenarioMetrics: Loading<ScenarioMetricsMapping>
): ColGroupDef<ScenarioMetadata> => {
    const createMetricsValueGetter = (
        field: keyof ScenarioMetrics
    ): ValueGetterFunc<ScenarioMetadata> => {
        return ({ data }) => {
            if (scenarioMetrics === "loading" || !data?.scenarioId) {
                return undefined;
            }
            return scenarioMetrics[data.scenarioId]?.[field];
        };
    };
    return {
        headerName: "Results",
        children: [
            {
                colId: "cost",
                headerName: "Cost",
                width: 90,
                cellRenderer: CurrencyCellRenderer,
                valueGetter: createMetricsValueGetter("sumAllocatedCost")
            },
            {
                colId: "costPerMw",
                headerName: "$ / MW",
                width: 90,
                cellRenderer: CurrencyCellRenderer,
                valueGetter: createMetricsValueGetter("costPerMw")
            }
        ]
    };
};

export const ScenariosTable: React.FC<{
    project: Loading<TrackedProject | undefined>;
    scenarios: Loading<ScenarioMetadata[]>;
    scenarioMetrics: Loading<ScenarioMetricsMapping>;
    height?: "auto" | number;
    gridRef: RefObject<AgGridReact>;
    scenarioStage: ScenarioStage;
}> = ({
    project,
    scenarios,
    scenarioMetrics,
    height,
    gridRef,
    scenarioStage
}) => {
    const navigate = useNavigate();
    const config = useRegionConfig();

    return (
        <BaseAgGrid<ScenarioMetadata>
            ref={gridRef}
            loadingData={scenarios}
            columnDefs={getColumnDefs({
                project,
                scenarioMetrics,
                config,
                scenarioStage
            })}
            height={height}
            onRowClicked={(event) => {
                const scenarioId = event.data?.scenarioId;
                if (scenarioId) {
                    navigate(`scenario/${scenarioId}`);
                }
            }}
            rowHeight={30}
            selectableRows
        />
    );
};
