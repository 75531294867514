import { ExportType, KMZ_TYPE } from "components/sidebar/exports/exportHelpers";
import {
    BusFilters,
    CapacityThresholds,
    MaybeMaxCosts
} from "types/busFilterTypes";
import {
    Bus,
    BusId,
    CapacityEnergyCost,
    ScopedBus,
    Substation,
    UnauthorizedBus
} from "types/busType";
import { ScopeType } from "types/scopeType";
import { ScreeningViewId } from "types/screeningViewType";
import { FacilityId } from "types/selectedFacility";
import { Voltage } from "types/voltageTypes";
import { track } from "./analytics";

const VOLTAGE_DROPDOWN_CHANGED_NAME = "voltage_dropdown_changed";
const NEW_VOLTAGE_PROPERTY = "new_voltage";
const OLD_VOLTAGE_PROPERTY = "old_voltage";

const MAX_ALLOCATED_COSTS_DROPDOWN_CHANGED_NAME =
    "max_allocated_costs_dropdown_changed";
const NEW_MAX_ALLOCATED_COSTS_PROPERTY = "new_max_allocated_costs";
const OLD_MAX_ALLOCATED_COSTS_PROPERTY = "old_max_allocated_costs";
const NEW_MAX_ALLOCATED_COSTS_ENABLED_PROPERTY =
    "new_max_allocated_costs_enabled";
const OLD_MAX_ALLOCATED_COSTS_ENABLED_PROPERTY =
    "old_max_allocated_costs_enabled";

const TOTAL_COSTS_DROPDOWN_CHANGED_NAME = "total_costs_dropdown_changed";
const NEW_TOTAL_COSTS_PROPERTY = "new_max_total_cost";
const OLD_TOTAL_COSTS_PROPERTY = "old_max_total_cost";
const NEW_TOTAL_COSTS_ENABLED_PROPERTY = "new_total_costs_enabled";
const OLD_TOTAL_COSTS_ENABLED_PROPERTY = "old_total_costs_enabled";

const BUS_SELECTED_FROM_SIDEBAR_NAME = "bus_selected_from_sidebar";
const BUS_SELECTED_FROM_LINE_DETAIL_NAME = "bus_selected_from_line_detail";
const UNAUTHORIZED_BUS_SELECTED_FROM_SIDEBAR_NAME =
    "unauthorized_bus_selected_from_sidebar";
const UNLOCK_BUS_ATTEMPT = "unlock_bus_attempt";
const SELECTION_CANCELED_FROM_MAP_NAME = "selection_canceled_from_map";
const SELECTION_CANCELED_FROM_BUS_DETAIL_CROSS =
    "selection_canceled_from_bus_detail_cross";
const SELECTION_CANCELED_FROM_LINE_DETAIL_CROSS =
    "selection_canceled_from_line_detail_cross";
const BUS_SELECTED_FROM_MAP_NAME = "bus_selected_from_map";
const UNAUTHORIZED_BUS_SELECTED_FROM_MAP_NAME =
    "unauthorized_bus_selected_from_map";
const BUS_SELECTED_FROM_TOGGLE_NAME = "bus_selected_from_toggle";
const CONNECTED_LINES_SECTION_TOGGLED_NAME = "connected_lines_section_toggled";
const LINE_SELECTED_FROM_BUS_DETAIL_PANE_NAME =
    "line_selected_from_bus_detail_pane";

const BUS_ID_PROPERTY = "bus_id";
const FACILITY_ID_PROPERTY = "facility_id";
const SELECTED_ID_PROPERTY = "selected_id";
const BUS_VOLTAGE_PROPERTY = "bus_voltage";
const BUS_DISPLAY_NAME_PROPERTY = "bus_display_name";
const BUS_REGION_PROPERTY = "bus_region";
const UNLOCK_BUS_SUCCESSFUL_PROPERTY = "unlock_bus_successful";
const CONNECTED_LINES_SECTION_OPENED_PROPERTY =
    "connected_lines_section_opened";
const IS_BUS_HIDDEN_PROPERTY = "is_bus_hidden";

const SUBSTATION_ID_PROPERY = "substation_id";
const SUBSTATION_DISPLAY_NAME_PROPERTY = "substation_display_name";

const CAPACITY_AVAILABLE_PROPERTY = "capacity_available";
const ENERGY_AVAILABLE_PROPERTY = "energy_available";
const EDITED_CAPACITY_AVAILABLE_PROPERTY = "capacity_available_edited";
const EDITED_ENERGY_AVAILABLE_PROPERTY = "energy_available_edited";
const EDITED_CHARGING_AVAILABLE_PROPERTY = "charging_available_edited";

const TOTAL_COSTS_PROPERTY = "total_costs";
const ALLOCATED_COSTS_PROPERTY = "allocated_costs";

const MORE_DETAILS_CONSTRAINTS_DIALOG_OPENED_NAME =
    "more_details_constraints_dialog_opened";
const MORE_DETAILS_CONSTRAINTS_DIALOG_CLOSED_NAME =
    "more_details_constraints_dialog_closed";

const CUSTOM_INJECTION_DIALOG_OPENED_NAME = "custom_injection_dialog_opened";
const CUSTOM_INJECTION_DIALOG_CLOSED_NAME = "custom_injection_dialog_closed";

const CUSTOM_INJECTION_UPDATED_NAME = "custom_injection_updated";
const PREVIOUS_CAPACITY_AVAILABLE_PROPERTY = "capacity_available_previous";
const PREVIOUS_ENERGY_AVAILABLE_PROPERTY = "energy_available_previous";
const PREVIOUS_CHARGING_AVAILABLE_PROPERTY = "charging_available_previous";

const CONTACT_SALES_UNAUTHORIZED_DETAIL_PANE_CLICKED_NAME =
    "contact_sales_unauthorized_detail_pane_clicked";

const STARTED_ZOOMING_NAME = "started_zooming";
const ENDED_ZOOMING_NAME = "ended_zooming";
const STARTED_DRAGGING_NAME = "started_dragging";
const ENDED_DRAGGING_NAME = "ended_dragging";

const ZOOM_PROPERTY = "zoom";
const LATITUDE_PROPERTY = "latitude";
const LONGITUDE_PROPERTY = "longitude";

const FLY_TO_LOCATION_FROM_SEARCH_NAME = "fly_to_location_from_search";

const SEARCH_RESULT_ID_PROPERTY = "search_result_id";
const SEARCH_RESULT_SUB_TEXT_PROPERTY = "search_result_sub_text";

const SCOPE_CHANGED_NAME = "scope_changed";
const NEW_SCOPE_TYPE_SELECTED_PROPERTY = "new_scope_type";

const INCLUDES_PRE_EXISTING_CHANGED_NAME = "includes_pre_existing_changed";
const INCLUDES_PRE_EXISTING_PROPERTY = "includes_pre_existing";

const HIDE_LOW_CONFIDENCE_BUSES_CHANGED_NAME =
    "hide_low_confidence_buses_changed";
const HIDE_LOW_CONFIDENCE_BUSES_PROPERTY = "hide_low_confidence_buses";

const HIDE_LOCKED_BUSES_CHANGED_NAME = "hide_locked_buses_changed";
const HIDE_LOCKED_BUSES_PROPERTY = "hide_locked_buses";

const COLOR_THRESHOLDS_CHANGED_NAME = "color_thresholds_changed";
const COLOR_THRESHOLDS_LOW_PROPERTY = "low_threshold";
const COLOR_THRESHOLDS_MEDIUM_PROPERTY = "medium_threshold";
const COLOR_THRESHOLDS_HIGH_PROPERTY = "high_threshold";

const SCREENING_VIEW_ID_PROPERTY = "screening_view_id";

const SCREENING_VIEW_COPY_LINK_NAME = "screening_view_copy_link";
const SCREENING_VIEW_DELETE_NAME = "screening_view_delete";
const SCREENING_VIEW_CREATE_NAME = "screening_view_create";
const SCREENING_VIEW_EXPORT_KMZ_NAME = "screening_view_export_kmz";
const SCREENING_VIEW_EXPORT_SHAPEFILE_NAME = "screening_view_export_shapefile";

export const segmentTrackVoltageDropdownChange = (
    newVoltages: readonly Voltage[],
    oldBusFilter: BusFilters
): void => {
    track(VOLTAGE_DROPDOWN_CHANGED_NAME, {
        [NEW_VOLTAGE_PROPERTY]: newVoltages,
        [OLD_VOLTAGE_PROPERTY]: oldBusFilter.voltages
    });
};

export const segmentTrackMaxAllocatedCostsDropdownChange = (
    newMaxAllocatedCosts: MaybeMaxCosts,
    oldMaxAllocatedCosts: MaybeMaxCosts
): void => {
    track(MAX_ALLOCATED_COSTS_DROPDOWN_CHANGED_NAME, {
        [NEW_MAX_ALLOCATED_COSTS_PROPERTY]: newMaxAllocatedCosts.maxCosts,
        [NEW_MAX_ALLOCATED_COSTS_ENABLED_PROPERTY]:
            newMaxAllocatedCosts.enabled,
        [OLD_MAX_ALLOCATED_COSTS_PROPERTY]: oldMaxAllocatedCosts.maxCosts,
        [OLD_MAX_ALLOCATED_COSTS_ENABLED_PROPERTY]: oldMaxAllocatedCosts.enabled
    });
};

export const segmentTrackMaxTotalCostsDropdownChange = (
    newTotalCosts: MaybeMaxCosts,
    oldTotalCosts: MaybeMaxCosts
): void => {
    track(TOTAL_COSTS_DROPDOWN_CHANGED_NAME, {
        [NEW_TOTAL_COSTS_PROPERTY]: newTotalCosts.maxCosts,
        [NEW_TOTAL_COSTS_ENABLED_PROPERTY]: newTotalCosts.enabled,
        [OLD_TOTAL_COSTS_PROPERTY]: oldTotalCosts.maxCosts,
        [OLD_TOTAL_COSTS_ENABLED_PROPERTY]: oldTotalCosts.enabled
    });
};
export const segmentTrackBusSelectedFromSidebar = (
    scopedBus: ScopedBus
): void => {
    track(
        BUS_SELECTED_FROM_SIDEBAR_NAME,
        convertScopedBusToSegmentProperties(scopedBus)
    );
};

export const segmentTrackUnauthorizedBusSelectedFromSidebar = (
    bus: UnauthorizedBus
): void => {
    track(UNAUTHORIZED_BUS_SELECTED_FROM_SIDEBAR_NAME, {
        [BUS_ID_PROPERTY]: bus.bus.id
    });
};

export const segmentTrackUnlockBusAttempt = (
    busId: BusId,
    unlockSuccessful: boolean
): void => {
    track(UNLOCK_BUS_ATTEMPT, {
        [BUS_ID_PROPERTY]: busId,
        [UNLOCK_BUS_SUCCESSFUL_PROPERTY]: unlockSuccessful
    });
};

export const segmentTrackSelectionCanceledFromMap = (): void => {
    track(SELECTION_CANCELED_FROM_MAP_NAME, {});
};

export const segmentTrackBusSelectionFromMap = (scopedBus: ScopedBus): void => {
    track(
        BUS_SELECTED_FROM_MAP_NAME,
        convertScopedBusToSegmentProperties(scopedBus)
    );
};

export const segmentTrackUnauthorizedBusSelectionFromMap = (
    bus: UnauthorizedBus
): void => {
    track(UNAUTHORIZED_BUS_SELECTED_FROM_MAP_NAME, {
        [BUS_ID_PROPERTY]: bus.bus.id
    });
};

export const segmentTrackBusSelectionFromToggle = (busId: BusId): void => {
    track(BUS_SELECTED_FROM_TOGGLE_NAME, { [BUS_ID_PROPERTY]: busId });
};

export const segmentTrackMoreDetailsConstraintsDialogOpened = (
    selectedId: BusId | FacilityId
): void => {
    track(MORE_DETAILS_CONSTRAINTS_DIALOG_OPENED_NAME, {
        [SELECTED_ID_PROPERTY]: selectedId
    });
};

export const segmentTrackMoreDetailsConstraintsDialogClosed = (
    selectedId: BusId | FacilityId
): void => {
    track(MORE_DETAILS_CONSTRAINTS_DIALOG_CLOSED_NAME, {
        [SELECTED_ID_PROPERTY]: selectedId
    });
};

export const segmentTrackSelectionCanceledFromBusDetailPaneCross = (
    busId: BusId
): void => {
    track(SELECTION_CANCELED_FROM_BUS_DETAIL_CROSS, {
        [BUS_ID_PROPERTY]: busId
    });
};

export const segmentTrackSelectionCanceledFromLineDetailPaneCross = (
    facilityId: FacilityId
): void => {
    track(SELECTION_CANCELED_FROM_LINE_DETAIL_CROSS, {
        [FACILITY_ID_PROPERTY]: facilityId
    });
};

export const segmentTrackConnectedLinesSectionToggled = (
    busId: BusId,
    connectedLinesSectionOpened: boolean
): void => {
    track(CONNECTED_LINES_SECTION_TOGGLED_NAME, {
        [BUS_ID_PROPERTY]: busId,
        [CONNECTED_LINES_SECTION_OPENED_PROPERTY]: connectedLinesSectionOpened
    });
};

export const segmentTrackLineSelectedFromBusDetailPane = (
    busId: BusId,
    facilityId: FacilityId
): void => {
    track(LINE_SELECTED_FROM_BUS_DETAIL_PANE_NAME, {
        [BUS_ID_PROPERTY]: busId,
        [FACILITY_ID_PROPERTY]: facilityId
    });
};

export const segmentTrackCustomInjectionDialogOpened = (
    selectedId: BusId | FacilityId
): void => {
    track(CUSTOM_INJECTION_DIALOG_OPENED_NAME, {
        [SELECTED_ID_PROPERTY]: selectedId
    });
};

export const segmentTrackCustomInjectionDialogClosed = (
    selectedId: BusId | FacilityId
): void => {
    track(CUSTOM_INJECTION_DIALOG_CLOSED_NAME, {
        [SELECTED_ID_PROPERTY]: selectedId
    });
};

export const segmentTrackCustomInjectionUpdated = (
    newCapacity: number,
    newEnergy: number,
    newCharging: number,
    previousCapacity: number,
    previousEnergy: number,
    previousCharging: number,
    selectedId: BusId | FacilityId
): void => {
    track(CUSTOM_INJECTION_UPDATED_NAME, {
        [EDITED_CAPACITY_AVAILABLE_PROPERTY]: newCapacity,
        [EDITED_ENERGY_AVAILABLE_PROPERTY]: newEnergy,
        [EDITED_CHARGING_AVAILABLE_PROPERTY]: newCharging,
        [PREVIOUS_CAPACITY_AVAILABLE_PROPERTY]: previousCapacity,
        [PREVIOUS_ENERGY_AVAILABLE_PROPERTY]: previousEnergy,
        [PREVIOUS_CHARGING_AVAILABLE_PROPERTY]: previousCharging,
        [SELECTED_ID_PROPERTY]: selectedId
    });
};

export const segmentTrackBusSelectedFromLineDetailPane = (
    busId: BusId,
    facilityId: FacilityId,
    isBusHidden: boolean
): void => {
    track(BUS_SELECTED_FROM_LINE_DETAIL_NAME, {
        [BUS_ID_PROPERTY]: busId,
        [FACILITY_ID_PROPERTY]: facilityId,
        [IS_BUS_HIDDEN_PROPERTY]: isBusHidden
    });
};

export const segmentTrackContactSalesUnauthorizedDetailPaneClicked = (
    busId: BusId
): void => {
    track(CONTACT_SALES_UNAUTHORIZED_DETAIL_PANE_CLICKED_NAME, {
        busId
    });
};

export const segmentTrackStartedZooming = (
    zoom: number,
    latitude: number,
    longitude: number
): void => {
    track(STARTED_ZOOMING_NAME, {
        [ZOOM_PROPERTY]: zoom,
        [LATITUDE_PROPERTY]: latitude,
        [LONGITUDE_PROPERTY]: longitude
    });
};

export const segmentTrackEndedZooming = (
    zoom: number,
    latitude: number,
    longitude: number
): void => {
    track(ENDED_ZOOMING_NAME, {
        [ZOOM_PROPERTY]: zoom,
        [LATITUDE_PROPERTY]: latitude,
        [LONGITUDE_PROPERTY]: longitude
    });
};

export const segmentTrackStartedDragging = (
    zoom: number,
    latitude: number,
    longitude: number
): void => {
    track(STARTED_DRAGGING_NAME, {
        [ZOOM_PROPERTY]: zoom,
        [LATITUDE_PROPERTY]: latitude,
        [LONGITUDE_PROPERTY]: longitude
    });
};

export const segmentTrackEndedDragging = (
    zoom: number,
    latitude: number,
    longitude: number
): void => {
    track(ENDED_DRAGGING_NAME, {
        [ZOOM_PROPERTY]: zoom,
        [LATITUDE_PROPERTY]: latitude,
        [LONGITUDE_PROPERTY]: longitude
    });
};

export const segmentTrackFlyToLocationFromSearch = (
    id: string,
    subText: string
): void => {
    track(FLY_TO_LOCATION_FROM_SEARCH_NAME, {
        [SEARCH_RESULT_ID_PROPERTY]: id,
        [SEARCH_RESULT_SUB_TEXT_PROPERTY]: subText
    });
};

export const segmentTrackScopeChanged = (scopeType: ScopeType): void => {
    track(SCOPE_CHANGED_NAME, {
        [NEW_SCOPE_TYPE_SELECTED_PROPERTY]: scopeType
    });
};

export const segmentTrackIncludePreExistingChanged = (
    includePreExistingConstraints: boolean
): void => {
    track(INCLUDES_PRE_EXISTING_CHANGED_NAME, {
        [INCLUDES_PRE_EXISTING_PROPERTY]: includePreExistingConstraints
    });
};

export const segmentTrackHideLowConfidenceBusesChanged = (
    hideLowConfidenceBuses: boolean
): void => {
    track(HIDE_LOW_CONFIDENCE_BUSES_CHANGED_NAME, {
        [HIDE_LOW_CONFIDENCE_BUSES_PROPERTY]: hideLowConfidenceBuses
    });
};

export const segmentTrackHideLockedBusesChanged = (
    hideLockedBuses: boolean
): void => {
    track(HIDE_LOCKED_BUSES_CHANGED_NAME, {
        [HIDE_LOCKED_BUSES_PROPERTY]: hideLockedBuses
    });
};

export const segmentTrackColorThresholdsChanged = (
    colorThresholds: CapacityThresholds
): void => {
    track(COLOR_THRESHOLDS_CHANGED_NAME, {
        [COLOR_THRESHOLDS_LOW_PROPERTY]: colorThresholds.lowThreshold,
        [COLOR_THRESHOLDS_MEDIUM_PROPERTY]: colorThresholds.mediumThreshold,
        [COLOR_THRESHOLDS_HIGH_PROPERTY]: colorThresholds.highThreshold
    });
};

export const segmentTrackScreeningViewCopyLink = (
    id: ScreeningViewId
): void => {
    track(SCREENING_VIEW_COPY_LINK_NAME, {
        [SCREENING_VIEW_ID_PROPERTY]: id
    });
};

export const segmentTrackScreeningViewDelete = (id: ScreeningViewId): void => {
    track(SCREENING_VIEW_DELETE_NAME, {
        [SCREENING_VIEW_ID_PROPERTY]: id
    });
};

export const segmentTrackScreeningViewCreate = (id: ScreeningViewId): void => {
    track(SCREENING_VIEW_CREATE_NAME, {
        [SCREENING_VIEW_ID_PROPERTY]: id
    });
};

export const segmentTrackScreeningViewExport = (
    id: ScreeningViewId,
    exportType: ExportType
): void => {
    const trackingKey =
        exportType === KMZ_TYPE
            ? SCREENING_VIEW_EXPORT_KMZ_NAME
            : SCREENING_VIEW_EXPORT_SHAPEFILE_NAME;

    track(trackingKey, {
        [SCREENING_VIEW_ID_PROPERTY]: id
    });
};

const convertScopedBusToSegmentProperties = (
    scopedBus: ScopedBus
): Record<string, string | number> => {
    return {
        ...convertBusToSegmentTrackedObject(scopedBus.bus),
        ...convertCapacityEnergyCostsToSegmentProperties(
            scopedBus.scopedCapacityEnergyCost
        )
    };
};

const convertCapacityEnergyCostsToSegmentProperties = (
    capacityEnergyCosts: CapacityEnergyCost
): Record<string, number> => {
    return {
        [CAPACITY_AVAILABLE_PROPERTY]: capacityEnergyCosts.capacitySize,
        [ENERGY_AVAILABLE_PROPERTY]: capacityEnergyCosts.energySize,
        [ALLOCATED_COSTS_PROPERTY]: capacityEnergyCosts.allocatedCosts,
        [TOTAL_COSTS_PROPERTY]: capacityEnergyCosts.totalCosts
    };
};

const convertBusToSegmentTrackedObject = (
    bus: Bus
): Record<string, string | number> => {
    return {
        [BUS_ID_PROPERTY]: bus.id,
        [BUS_DISPLAY_NAME_PROPERTY]: bus.busDisplayName,
        [BUS_VOLTAGE_PROPERTY]: bus.voltage,
        [BUS_REGION_PROPERTY]: bus.region,
        ...converSubstationToSegmentTrackedObject(bus.substation)
    };
};

const converSubstationToSegmentTrackedObject = (
    substation: Substation
): Record<string, string> => {
    return {
        [SUBSTATION_ID_PROPERY]: substation.id,
        [SUBSTATION_DISPLAY_NAME_PROPERTY]: substation.substationDisplayName
    };
};
